<script>
	import Base from '@backend/Base.vue';

	export default {
		name: "BoContact",
		extends: Base,
		data() {
			return {
				Name: "BoContact",
				status: {},
			}
		},
		mounted() {
			this.$set(this.$root, 'page', this)
			this.refreshData()
		},
		watch: {
			'$route.query'() {
				if(this.$route.params.id){
					this.$set(this.row, 'page', this)
					setTimeout(()=>{
						this.row.page = this.$route.query.page
					},500)
				}
				this.refreshData()
			},
			'filter.level'() {
				this.search()
			},
			'filter.search'(v){
		        this.search()
		    },
			'filter.status'(v){
		        this.search()
		    }
		}
	}
</script>

<template>
	<div class="container-fluid">
		<PageTitle></PageTitle>

		<!-- ======================================================================== 
			LIST DATA
		============================================================================= -->
		<div class="row" v-if="!$route.params.id">
			<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
				<div class="card">
					<div class="card-body">
						<VForm @resp="search">
							<div class="row gutter-10">
								<div class="col-sm-5">
									<h5 class="card-title">{{ObjectName}} List</h5>
								</div>
								<div class="col-sm-2">
									<select2 :options="status" v-model="filter.status">
					                    <option value="">-- Select Status --</option>
					                </select2>
								</div>
								<div class="col-sm-4">
									<div class="form-group mb-0">
										<div class="input-group">
											<input type="text" v-model="filter.search" class="form-control" placeholder="Search...">
											<div class="input-group-append">
												<button class="btn btn-info" type="button" @click="search()"><i
														class="fas fa-search"></i></button>
											</div>
										</div>
									</div>
								</div>
								<div class="col-3 col-sm-1">
									<router-link :to="{name:Name}" class="btn btn-block btn-warning">Reset</router-link>
								</div>
							</div>
						</VForm>
					</div>
					<div class="table-responsive ajax-table">
						<table class="table table-striped table-bordered">
							<thead>
								<tr>
									<th>#</th>
									<th>
										<SortField name="ac_full_name"></SortField>
									</th>
									<th>{{fields.ac_email}}</th>
									<th>{{fields.ac_subject}}</th>
									<th>{{fields.ac_is_active}}</th>
									<th class="text-center" width="120px">Action</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(v,k) in data.data" :key="k">
									<td class="number">{{(data.per_page*(data.current_page-1))+k+1}}</td>
									<td>{{(v.ac_full_name||'-')}}</td>
									<td>{{(v.ac_email||'-')}}</td>
									<td>{{(v.ac_subject||'-')}}</td>
									<td>
										<label v-if="v.ac_is_active =='Y'" class="label label-success">Read</label>
										<label v-if="v.ac_is_active =='N'" class="label label-warning">Unread</label>
									</td>
									<td class="btn-action text-center">
										<router-link v-if="page.moduleRole('Edit')" class="icon_action" :to="{name:Name,params:{id:v.id}}" v-tooltip="'Edit'"><i
												class="ti-marker-alt"></i></router-link>
									</td>
								</tr>
								<tr v-if="NotFound">
									<td colspan="99">
										<h3 class="tc">{{NotFound}}</h3>
									</td>
								</tr>
								<tr v-if="data.data===false">
									<td colspan="99">
										<LoadingSpinner light></LoadingSpinner>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<Pagination class="justify-content-end mt-3 mb-0" :data="data" :limit="3" @pagination-change-page="onPaging"></Pagination>
				</div>
			</div>
		</div>

		<!-- ======================================================================== 
			FORM CRUD	
		============================================================================= -->
		<div class="card" v-if="$route.params.id">
			<VForm @resp="submitForm" method="post">
				<div class="card-body">
					<div class="row mb-3">
						<div class="col-md-8">
							<h5 class="card-title">{{(row.id?"Edit":"Add")+" "+ObjectName}}</h5>
						</div>
					</div>
					<div class="info"></div>
					<div class="row">
						<div class="col-sm-12">
							<table class="table table-bordered">
								<tr>
									<td width="30%">Full Name</td>
									<td>{{row.ac_full_name}}</td>
								</tr>
								<tr>
									<td>Email</td>
									<td>{{row.ac_email}}</td>
								</tr>
								<tr>
									<td>Age</td>
									<td>{{row.ac_old}}</td>
								</tr>
								<tr>
									<td>Subject</td>
									<td>{{row.ac_subject}}</td>
								</tr>
								<tr>
									<td>Message</td>
									<td>{{row.ac_message}}</td>
								</tr>
								<tr>
									<td>Submit Date</td>
									<td>{{(row.ac_create_at).dates('format2')}}</td>
								</tr>
								<tr>
									<td>IP Address</td>
									<td>{{row.ac_ip}}</td>
								</tr>
								<tr>
									<td>Status</td>
									<td>
										<BoField class="mb-0" name="ac_is_active">
											<radio name="ac_is_active" v-model="row.ac_is_active" option="Y" :attr="validation('ac_is_active')">Read</radio>
											<radio name="ac_is_active" v-model="row.ac_is_active" option="N">Unread</radio>
										</BoField>
									</td>
								</tr>
							</table>
						</div>
						<div class="col-12">
							<div class="text-right">
								<button type="submit" class="fcbtn btn btn-info btn-outline btn-rounded btn-loading">Update Contact <i
										class="icon-arrow-right14 position-right"></i></button>
							</div>
						</div>
					</div>
				</div>
			</VForm>
		</div>
	</div>
</template>